import transactionsService from "../../../../services/transactionsService";
import $statusDictionary from "../../../../mixins/statusDictionary";
import $formatNumber from "../../../../mixins/formatNumber";
import $renderDate from "../../../../mixins/renderDate";

export default {
  name: "settlement-request",
  components: {},
  props: [],
  mixins: [$statusDictionary, $formatNumber, $renderDate],
  data() {
    return {
      withdrawal: "",
      statuses: "",
      statusText: "همه",
      status: null,
      typeText: "همه",
      type: null,
      currentPage: 1,
      tableHeaders: [
        "نام شرکت",
        "مجموع دارایی در زمان تراکنش",
        "مجموع دارایی در زمان درخواست",
        "مبلغ درخواست شده",
        "محاسبه دارایی در زمان درخواست",
        "زمان درخواست",
        "وضعیت",
        "عملیات"
      ],
      modalButtonLoader: false,
      confirmSettleModal: false,
      rejectSettleModal: false,
      confirmSettleItemId: null,
      confirmSettleDescription: "",
      rejectSettleItemId: null,
      rejectSettleDescription: "",
      descriptionModal: false,
      selectedItemDescription: ""
    };
  },
  computed: {},
  mounted() {
    this.getStatusFunc();
    this.getAdminWithdrawalFunc();
  },
  methods: {
    getStatusFunc() {
      transactionsService.getSettleStatus().then(response => {
        this.statuses = response.data;
      });
    },
    getAdminWithdrawalFunc() {
      transactionsService
        .getAdminWithdrawal(0, localStorage.getItem("bitrahAdminAccessToken"))
        .then(response => {
          this.withdrawal = response.data;
        });
    },
    confirmSettle() {
      this.modalButtonLoader = true;
      transactionsService
        .confirmWithdrawal(
          this.confirmSettleItemId,
          this.confirmSettleDescription,
          localStorage.getItem("bitrahAdminAccessToken")
        )
        .then(response => {
          this.withdrawal.content.find(
            el => el.id === this.confirmSettleItemId
          ).status = response.data;
        })
        .finally(() => {
          this.modalButtonLoader = false;
          this.hideConfirmSettleModal();
          this.getAdminWithdrawalFunc();
        });
    },
    rejectSettle() {
      this.modalButtonLoader = true;
      transactionsService
        .rejectWithdrawal(
          this.rejectSettleItemId,
          this.rejectSettleDescription,
          localStorage.getItem("bitrahAdminAccessToken")
        )
        .then(response => {
          this.withdrawal.content.find(
            el => el.id === this.rejectSettleItemId
          ).status = response.data;
        })
        .finally(() => {
          this.modalButtonLoader = false;
          this.hideRejectSettleModal();
          this.getAdminWithdrawalFunc();
        });
    },
    changeStatus(status, text) {
      this.status = status;
      this.statusText = text;
    },
    changeType(type, text) {
      this.type = type;
      this.typeText = text;
    },
    submitFilterWithdrawal() {
      transactionsService
        .getAdminWithdrawal(
          0,
          localStorage.getItem("bitrahAdminAccessToken"),
          this.status,
          this.type
        )
        .then(response => {
          this.currentPage = 1;
          this.withdrawal = response.data;
        })
        .catch(error => {
          this.$bvToast.toast(error.response.data.message, {
            title: this.$i18n.t("toast.errorTitle"),
            noCloseButton: true,
            variant: "custom",
            bodyClass: "dangerBackgroundColor dangerFontColor rtl text-right",
            headerClass:
              "dangerBackgroundColor dangerFontColor  rtl text-right",
            solid: false
          });
        });
    },
    getPaginationData(id) {
      transactionsService
        .getAdminWithdrawal(
          id - 1,
          localStorage.getItem("bitrahAdminAccessToken"),
          this.status,
          this.type
        )
        .then(response => {
          this.withdrawal = response.data;
        })
        .catch(error => {
          this.$bvToast.toast(error.response.data.message, {
            title: this.$i18n.t("toast.errorTitle"),
            noCloseButton: true,
            variant: "custom",
            bodyClass: "dangerBackgroundColor dangerFontColor rtl text-right",
            headerClass:
              "dangerBackgroundColor dangerFontColor  rtl text-right",
            solid: false
          });
        });
    },
    showConfirmSettleModal(itemId) {
      this.confirmSettleModal = true;
      this.confirmSettleItemId = itemId;
    },
    hideConfirmSettleModal() {
      this.confirmSettleModal = false;
      this.confirmSettleItemId = null;
      this.confirmSettleDescription = "";
    },
    showRejectSettleModal(itemId) {
      this.rejectSettleModal = true;
      this.rejectSettleItemId = itemId;
    },
    hideRejectSettleModal() {
      this.rejectSettleModal = false;
      this.rejectSettleItemId = null;
      this.rejectSettleDescription = "";
    },
    showDescriptionModal(itemDescription) {
      this.descriptionModal = true;
      this.selectedItemDescription = itemDescription;
    }
  }
};
