export default {
    methods: {
        $statusDictionary(status) {
            let exchangeStatus;
            switch (status) {
                case "SUBMITTED":
                    exchangeStatus = "ثبت شده";
                    break;
                case "BANK_PAYMENT":
                    exchangeStatus = "در انتظار پرداخت از درگاه ";
                    break;
                case "PAID":
                    exchangeStatus = "پرداخت شده";
                    break;
                case "TIMED_OUT":
                    exchangeStatus = "منقضی شده";
                    break;
                case "NEED_VERIFICATION":
                    exchangeStatus = "در انتظار تایید";
                    break;
                case "PROCESSING":
                    exchangeStatus = "در حال بررسی";
                    break;
                case "MANUAL_PROCESSING":
                    exchangeStatus = "در حال بررسی";
                    break;
                case "CONFIRMED":
                    exchangeStatus = "تایید شده";
                    break;
                case "EVACUATED":
                    exchangeStatus = "واریز شده به بیت‌راه";
                    break;
                case "UNCONFIRMED":
                    exchangeStatus = "تایید نشده";
                    break;
                case "EXPIRED":
                    exchangeStatus = "منقضی شده";
                    break;
                case "REFUSED":
                    exchangeStatus = "رد شده";
                    break;
                case "CANCELED":
                    exchangeStatus = "لغو شده";
                    break;
                case "UNMATCHED_CARD":
                    exchangeStatus = "عدم تطابق کارت بانکی";
                    break;
                case "NEGATIVE_BALANCE":
                    exchangeStatus = "در حال بررسی پشتیبان";
                    break;
                case "UNMATCHED_BALANCE":
                    exchangeStatus = "در حال بررسی پشتیبان";
                    break;
                case "REQUESTED":
                    exchangeStatus = "درخواست شده";
                    break;
                case "REJECTED":
                    exchangeStatus = "رد شده";
                    break;
                case "EVACUATION_ERROR":
                    exchangeStatus = "خطای تخلیه";
                    break;
                default:
                    exchangeStatus = "نا مشخص";
            }
            return exchangeStatus;
        },
    },
};
