/**
 * Render date
 */
export default {
    methods: {
        $renderDate(time) {
            return new Date(time * 1000);
        },
    },
};
