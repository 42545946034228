import axios from "./config/main-axios";
import authAxios from "./config/need-auth-axios";
import bitrahAxios from "./config/bitrah-axios";

class transactionsService {
  getTransactions(
    merchantId,
    pageNumber,
    status = null,
    type = null,
    refId = null,
    orderId = null
  ) {
    return authAxios.post("/merchant/panel/orders/rd", {
      merchantId: merchantId,
      pageableDTO: {
        page: pageNumber,
        size: 10,
        direction: "DEC",
        sortBy: "submitTime"
      },
      status: status,
      type: type,
      refId: refId,
      orderId: orderId
    });
  }

  getTransactionsPerPage(id) {
    return authAxios.get("/payments?page=" + id);
  }

  getTransactionInfo(id) {
    return axios.get("/payments/" + id);
  }

  getAdminTransactions(
    pageNumber = 0,
    merchantId,
    id = null,
    status = null,
    type = null,
    refId = null,
    orderId = null
  ) {
    return authAxios.post("/admin/order/all/rd", {
      searchMerchantId: id,
      merchantId: merchantId,
      pageableDTO: {
        page: pageNumber,
        size: 10,
        direction: "DEC",
        sortBy: "submitTime"
      },
      status: status,
      type: type,
      refId: refId,
      orderId: orderId
    });
  }

  getAdminWithdrawal(pageNumber, merchantId, status = null, type = null) {
    return authAxios.post("/admin/withdrawal/all/rd", {
      merchantId: merchantId,
      pageableDTO: {
        page: pageNumber,
        size: 10,
        direction: "DEC",
        sortBy: "requestTime"
      },
      status: status,
      type: type
    });
  }

  confirmWithdrawal(id, description, merchantId) {
    return authAxios.post("/admin/withdrawal/confirm/cmd", {
      id,
      description,
      merchantId
    });
  }

  rejectWithdrawal(id, description, merchantId) {
    return authAxios.post("/admin/withdrawal/reject/cmd", {
      id,
      description,
      merchantId
    });
  }

  getFailedWebHook(pageNumber) {
    return authAxios.post("/admin/webhook/failed/rd", {
      page: pageNumber,
      size: 10,
      direction: "DEC",
      sortBy: "submitTime"
    });
  }

  resendWebHook(data, merchantId) {
    data.merchantId = merchantId;
    return authAxios.post("/admin/webhook/resend/cmd", data);
  }

  getFailedEvacuation(pageNumber, merchantId, refId = null, orderId = null) {
    return authAxios.post("/admin/evacuation/failed/rd", {
      merchantId: merchantId,
      pageableDTO: {
        page: pageNumber,
        size: 10,
        direction: "DEC",
        sortBy: "submitTime"
      },
      refId: refId,
      orderId: orderId
    });
  }

  resendEvacuation(data, merchantId) {
    data.merchantId = merchantId;
    return authAxios.post("/admin/evacuation/resend/cmd", data);
  }

  getAdminAddresses(merchantId) {
    return authAxios.post(`/admin/wallet/all/rd`, {
      merchantId: merchantId
    });
  }

  getAdminEtherAddresses(merchantId) {
    return authAxios.post(`/admin/tank/all/rd`, {
      merchantId: merchantId
    });
  }
  toggleStatusAdminEtherAddresses(walletID, status, merchantId) {
    return authAxios.post(`admin/tank/toggle/cmd`, {
      id: walletID,
      enabled: status,
      merchantId: merchantId
    });
  }
  addEtherAdminAddresses(merchantId) {
    return authAxios.post(`admin/tank/create/USDT/wr`, {
      merchantId: merchantId
    });
  }

  addAdminAddresses(coin, addressValue, merchantId) {
    return authAxios.post(`admin/wallet/add/wr`, {
      address: addressValue,
      coin: coin,
      merchantId: merchantId
    });
  }

  removeAdminAddresses(walletID, merchantId) {
    return authAxios.post(`admin/wallet/remove/wr`, {
      id: walletID,
      merchantId: merchantId
    });
  }

  toggleStatusAdminAddresses(walletID, status, merchantId) {
    return authAxios.post(`admin/wallet/toggle/cmd`, {
      id: walletID,
      enabled: status,
      merchantId: merchantId
    });
  }

  getCorruptedPayment(
    pageNumber,
    merchantId,
    status = null,
    type = null,
    refId = null,
    orderId = null
  ) {
    return authAxios.post("/admin/corrupted/all/rd", {
      merchantId: merchantId,
      pageableDTO: {
        page: pageNumber,
        size: 10,
        direction: "DEC",
        sortBy: "id"
      },
      status: status,
      type: type,
      refId: refId,
      orderId: orderId
    });
  }

  getSubmittedOrders(
    pageNumber,
    merchantId,
    status = null,
    type = null,
    refId = null,
    orderId = null
  ) {
    return authAxios.post("/admin/unprocessed/all/rd", {
      merchantId: merchantId,
      pageableDTO: {
        page: pageNumber,
        size: 10,
        direction: "DEC",
        sortBy: "id"
      },
      addressStatus: status,
      type: type,
      refId: refId,
      orderId: orderId
    });
  }

  getUnsuccessfulInquiries(
    pageNumber,
    merchantId,
    type = null,
    refId = null,
    orderId = null
  ) {
    return authAxios.post("/admin/inquiry/unsuccessful/rd", {
      merchantId: merchantId,
      pageableDTO: {
        page: pageNumber,
        size: 10,
        direction: "DEC",
        sortBy: "id"
      },
      type: type,
      refId: refId,
      orderId: orderId
    });
  }

  retryUnsuccessfulInquiries(data, merchantId) {
    data.merchantId = merchantId;
    return authAxios.post("/admin/inquiry/retry/cmd", data);
  }

  getFailedEtherInjection(
    pageNumber,
    merchantId,
    type = null,
    refId = null,
    orderId = null
  ) {
    return authAxios.post("/admin/injection/unsuccessful/rd", {
      merchantId: merchantId,
      pageableDTO: {
        page: pageNumber,
        size: 10,
        direction: "DEC",
        sortBy: "id"
      },
      type: type,
      refId: refId,
      orderId: orderId
    });
  }

  retryFailedEtherInjection(data, merchantId) {
    data.merchantId = merchantId;
    return authAxios.post("/admin/injection/retry/cmd", data);
  }

  evacuateCorruptedPayment(data, merchantId) {
    data.merchantId = merchantId;
    return authAxios.post("/admin/corrupted/evacuate/cmd", data);
  }

  refundCorruptedPayment(data, merchantId) {
    data.merchantId = merchantId;
    return authAxios.post("/admin/corrupted/refund/cmd", data);
  }

  checkSubmittedOrder(address, merchantId) {
    return authAxios.post("/admin/unprocessed/check/cmd", {
      merchantId: merchantId,
      address: address
    });
  }

  getPrepareSubmittedOrder(address, merchantId) {
    return authAxios.post("/admin/unprocessed/prepare/cmd", {
      merchantId: merchantId,
      address: address
    });
  }

  prepareSubmittedOrderToRefund(address, merchantId) {
    return authAxios.post("/admin/unprocessed/prepareRefundError/cmd", {
      merchantId: merchantId,
      address: address
    });
  }

  refundSubmittedOrder(address, merchantId) {
    return authAxios.post("/admin/unprocessed/refund/cmd", {
      merchantId: merchantId,
      address: address
    });
  }

  evacuateSubmittedOrder(address, merchantId) {
    return authAxios.post("/admin/unprocessed/evacuate/cmd", {
      merchantId: merchantId,
      address: address
    });
  }

  getDetailsSubmittedOrder(address, merchantId) {
    return authAxios.post("/admin/unprocessed/details/cmd", {
      merchantId: merchantId,
      address: address
    });
  }

  getErrors() {
    return bitrahAxios.get("/manifest/board/crypto/currency/errors/rd");
  }

  getMerchantOrdersStatus() {
    return bitrahAxios.get("/manifest/board/order/status/rd");
  }

  getMerchantOrdersTypes() {
    return bitrahAxios.get("/manifest/board/order/type/rd");
  }

  getstatus() {
    return bitrahAxios.get("/manifest/board/order/status/rd");
  }

  getSubmittedOrdersStatus() {
    return authAxios.get("/admin/unprocessed/status/code/rd");
  }

  getSettleStatus() {
    return bitrahAxios.get("/manifest/board/order/status/rd");
  }

  getCorruptedStatus() {
    return bitrahAxios.get("/manifest/board/order/status/corrupted/rd");
  }
}

export default new transactionsService();
