export default {
    methods: {
        $formatNumber(value, decimals = 0) {
            if (typeof value == "string" && value.indexOf(",") > -1) {
                value = value.replace(/,/gi, "");
            }

            let nStr = parseFloat(value);
            nStr = nStr.toFixed(decimals) + "";
            // eslint-disable-next-line no-useless-escape
            nStr = nStr.replace(/\,/g, "");
            let x = nStr.split(".");
            let x1 = x[0];
            let x2 = x.length > 1 ? "." + x[1] : "";
            let rgx = /(\d+)(\d{3})/;
            while (rgx.test(x1)) {
                x1 = x1.replace(rgx, "$1" + "," + "$2");
            }
            return x1 + x2;
        },
    },
};
