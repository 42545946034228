import { render, staticRenderFns } from "./settlementRequest.html?vue&type=template&id=3353016e&scoped=true&"
import script from "./settlementRequest.js?vue&type=script&lang=js&"
export * from "./settlementRequest.js?vue&type=script&lang=js&"
import style0 from "./settlementRequest.scss?vue&type=style&index=0&id=3353016e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3353016e",
  null
  
)

export default component.exports